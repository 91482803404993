/**
 * @file トップページで使用するjsファイル
 *
*/
import PageLoading from '../../modules/_loading';

const loading = new PageLoading('pageloading');
document.addEventListener('DOMContentLoaded', loading.loadBar(), false);

$(document).ready(function () {
  setFullScreenBackground();

  $(window).on('resize', function () {
    setFullScreenBackground();
  });

  function setFullScreenBackground () {
    const $header = $('#header');
    const $target = $('#mainvisual-overview');
    const windowHeight = $(window).height();
    const headerHeight = $header.height();
    const fullScrennBgHeight = Math.abs(Math.round(windowHeight - headerHeight)) + 110;
    
    if ($(window).width() > 896) {
      $target.css('height', fullScrennBgHeight );
    } else {
      $target.removeAttr('style');
    }
  }
});
