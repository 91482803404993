import '../lib/_jquery.waypoints.min';

const Component = {
  NAME: 'PageLoading',
  VERSION: '1.0.0',
};

const StorageProps = {
  NAME: 'loading',
};

const ClassNames = {
  FIXED_CONTENT: 'fixed-content',
};

const Cookies = {
  EXPIRE: 1 // Days
};

class PageLoading {
  constructor (element) {
    this._element = document.getElementById(element);
    this._bar = this._element.children[0].children[1].children[0];
    this.images = document.images;
    this.imageLength = 0;
    this.count = 0;
    this.percentage = 0;

    this.init();
  }

  init () {
    document.body.classList.add(ClassNames.FIXED_CONTENT);
    this.countImages();
  }

  countImages () {
    this.imageLength = this.images.length;
  }

  loadBar () {
    const that = this;

    if (this.getCookies(StorageProps.NAME) === 'true') {
      that._element.style.opacity = 0;
      document.body.classList.remove(ClassNames.FIXED_CONTENT);
      that.setAnimation();

      setTimeout(function () {
        that._element.remove();
      }, 1200);

      return;
    } else {
      this._element.children[0].children[0].style.opacity = 1;
      this._element.children[0].children[0].style.left = 'auto';
    }

    if (this.imageLength === 0) return this.doneLoading();

    for(let i = 0; i < this.imageLength; i++) {
      const img   = new Image();
      img.onload  = imgLoaded;
      img.onerror = imgLoaded;
      img.src     = this.images[i].src;
    }

    function imgLoaded () {
      that.count += 1;
      that.percentage = ((100 / that.imageLength * that.count) << 0);
      
      const scale = that.percentage / 100; // scale is equal to 1.
      that._bar.style.transform = `scaleX(${ scale })`;

      if (that.count === that.imageLength) return that.doneLoading();
    }
  }

  doneLoading () {
    const that = this;

    // Set cookie
    this.setCookies(StorageProps.NAME, true, Cookies.EXPIRE);

    setTimeout(function () {
      that._element.style.transform = 'translateX(-100%)';
      document.body.classList.remove(ClassNames.FIXED_CONTENT);
      that.setAnimation();
    }, 800);
  }

  setCookies (name, value, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));

    const expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }

  getCookies (cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }

    return '';
  }

  setAnimation () {
    $(function () {
      var waypoints = $('.js-animate, .js-animate-group > *').waypoint(function() {
        $(this.element).addClass('is-animated');
      },
      {
        offset: '80%',
        triggerOnce: true
      });
    });
  }
}

export default PageLoading;
